import React, { useRef, useState } from "react";
import styles from "../ContentGeneration/blogposter.module.css";
import generate from "../../assests/images/Blogposter/generate.png";
import retry from "../../assests/images/Blogposter/regenerate.png";
import cancel from "../../assests/images/Blogposter/cancel.png";
import layerIcon from "../../assests/images/Blogposter/layer.png";
import {
  Generate_Outliners,
  GetTittle,
  regenerate_outlines,
} from "../../commonApi";
import drag from "../../assests/images/Blogposter/drag.svg";
import Charging from "../../Charging";
import { Toast } from "primereact/toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Outliners = ({
  title,
  onInstructionsChange,
  onKeywordsChange,
  onOutlinesChange,
  instructions,
  keywords,
  outlines,
}) => {
  const [open, setOpen] = useState(false);
  const [layers, setLayers] = useState([]);
  const toast = useRef(null);

  const handleInstructionsChange = (e) => {
    onInstructionsChange(e.target.value);
  };

  const handleKeywordsChange = (e) => {
    onKeywordsChange(e.target.value);
  };

  const handleRetryClick = async (outline, index) => {
    setOpen(true); // Show loading indicator

    const data = {
      title,
      outlines_instructions: instructions,
      keywords,
      list_of_outlines: outlines,
      regenerating_outline: outline,
    };

    try {
      const response = await regenerate_outlines(data);
      if (response.success) {
        const regeneratedTitle = response.titles;
        const updatedOutlines = [...outlines];
        updatedOutlines[index] = regeneratedTitle;
        onOutlinesChange(updatedOutlines);
        console.log("Outline regenerated successfully:", regeneratedTitle);
        // You may want to update the specific outline based on the response
        // or notify the user that it has been regenerated
      } else {
        console.error("Failed to regenerate outline:", response.message);
      }
    } catch (error) {
      console.error("Error in regenerating outline:", error);
    } finally {
      setOpen(false); // Hide loading indicator
    }
  };

  const handleGenerateClick = async () => {
    setOpen(true);
    try {
      const data = { title, instructions, keywords };
      const response = await Generate_Outliners(data);
      if (response.success) {
        onOutlinesChange(response.outlines);
      } else {
        console.error("Failed to generate outlines:", response.message);
      }
    } catch (error) {
      console.error("Error generating outlines:", error);
    } finally {
      setOpen(false);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(outlines);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onOutlinesChange(items);
  };

  const addLayer = () => {
    setLayers([...layers, ""]); // Add an empty string to represent a new layer
  };

  const handleLayerChange = (value, index) => {
    const newLayers = [...layers];
    newLayers[index] = value;
    setLayers(newLayers);
  };

  const saveLayersToOutlines = () => {
    onOutlinesChange([...outlines, ...layers]);
    setLayers([]); // Optionally clear layers after saving
  };

  const handleCancelClick = (indexToRemove) => {
    const updatedOutlines = outlines.filter(
      (_, index) => index !== indexToRemove
    );
    onOutlinesChange(updatedOutlines); // Update the parent component's outlines
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.outlinertitle}>
        <h5>Title</h5>
        <h5>{title}</h5>

        <h5>Instructions</h5>
        <div className={styles.instructions}>
          <textarea
            className={styles.text}
            value={instructions}
            onChange={handleInstructionsChange}
            placeholder="Eg: 'The Quantum Leap: Machine Learning in the Age of Big Data'"
          />
        </div>

        <h5>Key Words</h5>
        <div className={styles.instructions2}>
          <textarea
            className={styles.text}
            value={keywords}
            onChange={handleKeywordsChange}
            placeholder="Eg: 'Continuous Integration, Continuous Delivery, Continuous Training'"
          />
        </div>

        <div>
          <button className={styles.commonbtn3} onClick={handleGenerateClick}>
            <img src={generate} alt="Generate" />
            Generate
          </button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="outlines">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {outlines?.map((outline, index) => (
                  <Draggable
                    key={index}
                    draggableId={`outline-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.tex}
                      >
                        <div className={styles.textt}>
                          <input
                            className={styles.inp2}
                            type="text"
                            readOnly
                            value={outline}
                          />
                          <img className={styles.imgg} src={drag} alt="drag" />
                        </div>
                        <div className={styles.imagessec}>
                          <img
                            src={retry}
                            alt="Retry"
                            onClick={() => handleRetryClick(outline, index)}
                          />
                          <img
                            src={cancel}
                            alt="Cancel"
                            onClick={() => handleCancelClick(index)}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {layers?.map((layer, index) => (
          <input
            key={index}
            className={styles.inp3}
            type="text"
            value={layer}
            onChange={(e) => handleLayerChange(e.target.value, index)}
            placeholder="Enter a new layer"
          />
        ))}

        <div className={styles.layer}>
          <button className={styles.commonbtn4} onClick={addLayer}>
            <img src={layerIcon} alt="Add Layer" />
            Add Outlines
          </button>
          <button className={styles.commonbtn5} onClick={saveLayersToOutlines}>
            Save Outlines
          </button>
        </div>
      </div>
    </div>
  );
};

export default Outliners;
