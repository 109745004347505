import React, { useRef, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import QuillEditor from "./QuillEditor";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import styles from "./blogSetting.module.css";
import { Toast } from "primereact/toast";
import Charging from "../../Charging/index";
import Edit from "../../assests/images/texttoimage/edit.png";
import Save from "../../assests/images/texttoimage/save.png";
import Download from "../../assests/images/texttoimage/download.png";
import Back from "../../assests/images/texttoimage/back.png";
import llight from "../../assests/images/texttoimage/llight.png";
import dlight from "../../assests/images/texttoimage/dlight.png";
import ldark from "../../assests/images/texttoimage/ldark.png";
import ddark from "../../assests/images/texttoimage/ddark.png";
import settings from "../../assests/images/texttoimage/settings.png";
import none from "../../assests/images/texttoimage/none.png";
import nonenew from "../../assests/images/texttoimage/nonenew.png";
import Anime from "../../assests/images/texttoimage/Anime.png";
import Bokeh from "../../assests/images/texttoimage/Bokeh.png";
import Cenematic from "../../assests/images/texttoimage/Cenematic.png";
import LineArt from "../../assests/images/texttoimage/Line art.png";
import OilPainting from "../../assests/images/texttoimage/Oil Painting.png";
import Graphic from "../../assests/images/texttoimage/Graphic.png";
import Demoimg from "../../assests/images/texttoimage/demoimg.jpg";
import outlookIcon from "../../assests/images/texttoimage/outlookIcon.png";
import linkedinIcon from "../../assests/images/texttoimage/linkedIn.png";
import gmailIcon from "../../assests/images/texttoimage/Gmail.png";
import mediumIcon from "../../assests/images/texttoimage/medium.png";
import IconEdit from "../../assests/images/texttoimage/iconedit.png";
import magic from "../../assests/images/texttoimage/magic.png";
import act from "../../assests/images/appstore/activeToggle.png";
import inact from "../../assests/images/appstore/inactiveToggle.png";
import rewrite from "../../assests/images/appstore/rewrite.png";
import compress from "../../assests/images/appstore/compress.png";
import longwinded from "../../assests/images/appstore/longwinded.png";
import refine from "../../assests/images/appstore/refine.png";
import summary from "../../assests/images/appstore/summary.png";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css"; // Ensure the CSS is imported
import star from "../../assests/images/texttoimage/star.png";
import imageupload from "../../assests/images/texttoimage/uploadimage.png";
import CustomToolbar from "./CustomeToolBar";
import html2pdf from "html2pdf.js";
import Toolbar from "./Toolbar";
import { flushSync } from "react-dom";
import {
  PostMagicQuery,
  PostMagicImageQuery,
  PostQuery,
  GetImages,
  GetContent,
  ReplaceAPI,
  GetBlogRoles,
  SaveDoc,
  postGmail,
  Savefile,
  PostMedium,
  PostLinkeIn,
  PostAddrole,
} from "../../commonApi";
// import { content } from "html2canvas/dist/types/css/property-descriptors/content";

const Poster = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [selectedText, setSelectedText] = useState("");
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const quillRef = useRef(null);
  const [isClicked, setIsClicked] = useState(false);
  const [pop, setpop] = useState(false);
  const [isToolbarVisible, setIsToolbarVisible] = useState(true); // State for toolbar visibility
  const [selectedImage, setSelectedImage] = useState(null);
  // const imageHandler = () => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.click();

  //   input.onchange = () => {
  //     const file = input.files[0];
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const quill = quillRef.current.getEditor();
  //       const range = quill.getSelection();
  //       quill.insertEmbed(range.index, 'image', reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   };
  // };
  const [isActive, setIsActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [replaceCondition, setReplaceCondition] = useState("");
  const { templateId } = useParams();
  const [replaceText, setReplaceText] = useState("");
  const [docurl, setDocurl] = useState("");
  const [roles, setRoles] = useState([]); // Store the list of roles from backend
  const [selectedRole, setSelectedRole] = useState(""); // Selected role from dropdown
  const [isAddingRole, setIsAddingRole] = useState(false); // Track whether adding a new role
  const [customRole, setCustomRole] = useState(""); // Custom role input value
  const [temperature, setTemperature] = useState(0.5); // Track temperature
  const [fixedDescription, setFixedDescription] = useState(""); // Fixed description from backend
  const [description, setDescription] = useState(""); // Editable description for custom role
  const [show, setshow] = useState(false);
  const [download, setDownload] = useState(false);
  const [back, setback] = useState(false);
  const funError = (s3Code) => {
    setOpen(false);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: s3Code,
      life: 2000,
    });
  };

  const toggleActive = () => {
    setIsActive((prevState) => !prevState);
  };

  const textareaRef = useRef(null);
  const [query, setQuery] = useState("");
  const [newText, setNewText] = useState("");
  const [title, setTitile] = useState("");
  const [genresponse, setGenresponse] = useState("");
  const [imageSize, setImageSize] = useState("1024x1024");
  const [style, setStyle] = useState("None");
  const [colorTheme, setColorTheme] = useState("None");
  const [lightingStyle, setLightingStyle] = useState("None");
  const [composition, setComposition] = useState("None");
  const [images, setImages] = useState([]);
  const [contentData, setContentData] = useState(null); // Store the content data
  // Handler for image size selection
  const quillEditorRef = useRef(null);
  const decodeUnicode = (str) => {
    return str.replace(/\\u([0-9a-fA-F]{4})/g, (match, grp) => {
      return String.fromCharCode(parseInt(grp, 16));
    });
  };

  // const handleDownloadButtonClick = async () => {
  //   console.log("Content Data:", contentData);

  //   // Convert images to base64 data URLs
  //   const htmlWithBase64Images = await convertImagesToBase64(contentData);

  //   // Create a temporary container element
  //   const element = document.createElement('div');
  //   element.innerHTML = htmlWithBase64Images;

  //   // Append the element to the body
  //   document.body.appendChild(element);

  //   try {
  //     // Define options for PDF generation
  //     const options = {
  //       margin: [0.5, 0.5],
  //       filename: `${title || 'document'}.pdf`,
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: {
  //         scale: 2,
  //         useCORS: true,
  //         allowTaint: false,
  //       },
  //       jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  //     };

  //     // Generate and download the PDF
  //     await html2pdf().from(element).set(options).save();
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //   } finally {
  //     // Clean up the temporary container
  //     document.body.removeChild(element);
  //   }
  // };
  useEffect(() => {
    setshow(pop);
  }, [pop]);

  console.log("pop and show", pop, show);
  const handleDownloadButtonClick = async (data) => {
    if (!data) {
      console.log("downloading");
      const link = document.createElement("a");
      link.href = docurl;
      link.download = "document.pdf"; // Set a default file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false);
    } else {
      setDownload(true);
      console.log("in");
    }
  };

  const convertImagesToBase64 = async (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const imgElements = doc.querySelectorAll("img");

    const promises = Array.from(imgElements).map(async (img) => {
      const imgUrl = img.getAttribute("src");
      if (imgUrl && !imgUrl.startsWith("data:")) {
        try {
          const response = await fetch(imgUrl, { mode: "no-cors" });
          const blob = await response.blob();

          const reader = new FileReader();
          const dataUrl = await new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });

          img.setAttribute("src", dataUrl);
        } catch (error) {
          console.error("Error converting image to base64:", error);
        }
      }
    });

    await Promise.all(promises);
    return doc.body.innerHTML;
  };
  const handleImageSizeChange = (event) => {
    setImageSize(event.target.value);
  };

  // Handler for style selection
  const handleStyleChange = (style) => {
    setStyle(style);
  };

  // Handler for color theme selection
  const handleColorThemeChange = (theme) => {
    setColorTheme(theme);
  };

  // Handler for lighting style selection
  const handleLightingStyleChange = (style) => {
    setLightingStyle(style);
  };

  // Handler for composition selection
  const handleCompositionChange = (composition) => {
    setComposition(composition);
  };

  const autoResize = (e) => {
    setQuery(e.target.value);
    const textarea = e.target;
    textarea.style.height = "auto"; // Reset height to auto to recalculate
    const newHeight = Math.min(textarea.scrollHeight, 100); // Limit to max-height
    textarea.style.height = `${newHeight}px`; // Set the new height
  };

  // Common logic to process the query
  const processQuery = async () => {
    if (selectedButton === "image" || selectedSetting === "image") {
      if (isActive) {
        if (query.trim()) {
          await sendQueryToAPI(query); // Send query to API for images
        }
      } else {
        if (query.trim()) {
          await sendMagicQueryToAPI(query); // Send magic query to API
        }
      }
    } else if (selectedButton === "text" || selectedSetting === "text") {
      await textGenerationAPI(query); // Send query for text generation
    } else {
      funError("Select one option to query"); // Handle error for no option selected
    }
  };

  // Handle Enter key press
  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default Enter behavior
      await processQuery(); // Call the shared function
    }
  };

  // Handle button click
  const handlesendButtonClick = async () => {
    await processQuery(); // Call the shared function
  };

  const sendQueryToAPI = async (queryText) => {
    const requestData = {
      user_input: queryText, // The user query
      image_style: style, // Static value
      lightening_style: lightingStyle, // Static value
      color_theme: colorTheme, // Static value
      camera_angle: composition, // Static value
    };

    try {
      setOpen(true);
      const response = await PostMagicQuery(requestData);
      if (response.success) {
        setQuery(response.text);

        await new Promise((resolve) => setTimeout(resolve, 5));
        setIsActive(false);
      } else {
        funError(response.message);
      }
      setOpen(false);

      console.log("API response:", response);
    } catch (error) {
      console.error("Error sending query:", error);
    }
  };
  const sendMagicQueryToAPI = async (queryText) => {
    const requestData = {
      image_size: imageSize,
      image_quality: "hd",
      magic_prompt: queryText,
      project_type: "blog",
      image_extension: "JPEG",
    };

    try {
      setOpen(true);
      // setsessionid('bf392b12-aeeb-41df-b3c9-8c021ba56595');
      const response = await PostMagicImageQuery(requestData, templateId);
      if (response.success) {
        setQuery("");
        loadImages();
        setSelectedButton("image");
        setIsToolbarVisible(false);
        setIsClicked(false);
        setSelectedSetting(null);
      } else {
        funError(response.message);
      }
      setOpen(false);

      console.log("API response:", response);
    } catch (error) {
      console.error("Error sending query:", error);
    }
  };
  const textGenerationAPI = async (queryText) => {
    const requestData = {
      input_text: queryText,
      actor_role_description: fixedDescription,
    };

    try {
      setOpen(true);
      const response = await PostQuery(requestData);
      if (response.success) {
        // setNewText(response.data);
        setGenresponse(response.data);
        await new Promise((resolve) => setTimeout(resolve, 5));
        setSelectedButton("text");
        setSelectedSetting(null);

        setQuery("");
        await new Promise((resolve) => setTimeout(resolve, 5));
        setOpen(false);
        console.log("cvxcxc", newText);
      } else {
        funError(response.message);
      }
      setOpen(false);

      console.log("API response:", response);
    } catch (error) {
      console.error("Error sending query:", error);
    }
  };
  const saveContentAPI = async () => {
    const requestData = {
      doc_content: contentData, // Static value
    };

    try {
      setOpen(true);
      const response = await SaveDoc(requestData, templateId);
      if (response.success) {
        console.log("pop show1", pop, show);

        setshow(false);
        setpop(false);

        await new Promise((resolve) => setTimeout(resolve, 10));
        // Ensure this function updates the text accordingly
        console.log("pop show2", pop, show, download);
        if (download) {
          console.log("pop show3", pop, show);

          setDownload(false);
          handleDownloadButtonClick();
        } else if (back) {
          handleNavigation();
        } else {
          loadContent();
        }
      } else {
        setshow(false);
        await new Promise((resolve) => setTimeout(resolve, 5)); // Ensure this function updates the text accordingly

        setpop(false);
        await new Promise((resolve) => setTimeout(resolve, 5));
        funError(response.message);
        console.log("pop show3", pop, show);
      }
      setOpen(false);

      console.log("API response:", response);
    } catch (error) {
      console.error("Error sending query:", error);
    }
  };
  console.log("pop show outside", pop, show, download);
  useEffect(() => {
    const textReplaceAPI = async () => {
      if (replaceCondition) {
        const requestData = {
          input_text: selectedText,
          input_condition: replaceCondition,
        };

        try {
          setOpen(true);
          const response = await ReplaceAPI(requestData);
          if (response.success) {
            setReplaceText(response.data);
            await new Promise((resolve) => setTimeout(resolve, 5)); // Ensure this function updates the text accordingly
            setReplaceCondition("");
            await new Promise((resolve) => setTimeout(resolve, 5));
          } else {
            funError(response.message);

            setReplaceCondition("");
            await new Promise((resolve) => setTimeout(resolve, 5));
          }
          setOpen(false);

          console.log("API response:", response);
        } catch (error) {
          console.error("Error sending query:", error);
        }
      }
    };

    textReplaceAPI();
  }, [replaceCondition]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto
      textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`; // Set height based on content
    }
  }, []);

  // Function to fetch content based on templateId
  const loadContent = async () => {
    try {
      const response = await GetContent(templateId);
      if (response.success) {
        // setContentData(response.data.content.String);
        setContentData(response.test);
        console.log("pure content data", contentData); // Assuming 'result' is the relevant content data
        await new Promise((resolve) => setTimeout(resolve, 5));
        setTitile(response.data.title.String);
        await new Promise((resolve) => setTimeout(resolve, 5));
        setDocurl(response.data.url.String);
      } else {
        funError(response.message);
      }
    } catch (err) {
      funError("Failed to fetch content");
    } finally {
      setOpen(false);
    }
  };

  // Call loadContent when the component loads or templateId changes
  useEffect(() => {
    loadContent();
  }, [templateId]);

  const handleClick = () => {
    setIsToolbarVisible(false);
    setIsClicked(true);

    setIsToolbarVisible(false);
    setSelectedButton(null);
    setSelectedSetting(null);
    setSelectedImage(null);
    console.log("Icon clicked, state is now true");
  };
  useEffect(() => {
    // Check if the text is empty first
    if (selectedText === "") {
      setIsClicked(false); // Set isClicked to false when selectedText is empty
    }

    // Check for other conditions after handling selectedText
    if (
      selectedButton === null &&
      selectedSetting === null &&
      isClicked === false
    ) {
      setIsToolbarVisible(true); // Show toolbar if all conditions are met
    } else {
      setIsToolbarVisible(false); // Hide toolbar otherwise
    }
  }, [selectedText, selectedButton, selectedSetting, isClicked]);

  const handleEditClick = () => {
    setSelectedButton(null);
    setSelectedSetting(null);
    setSelectedImage(null);
    setIsToolbarVisible(true);
  };
  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
    setIsToolbarVisible(false);
    setIsClicked(false);
    setSelectedImage(null);
    setSelectedSetting(null); // Clear settings when the button itself is clicked
  };

  const handleSettingClick = (e, settingId) => {
    e.stopPropagation(); // Prevent the button's onClick from being triggered
    setSelectedSetting(settingId);
    setSelectedImage(null);
    setIsToolbarVisible(false);
    setIsClicked(false);
    setSelectedButton(null); // Clear the button selection when the settings icon is clicked
  };

  const handleSelectionChange = (text, range) => {
    if (range && range.index !== undefined && range.length !== undefined) {
      setSelectedText(text);

      const quill = document.querySelector(".ql-editor");
      if (quill) {
        const quillBounds = quill.getBoundingClientRect();
        const quillEditor = quillRef.current.getEditor();
        const bounds = quillEditor.getBounds(range.index, range.length);

        console.log("Editor Bounds:", quillBounds);
        console.log("Selection Bounds:", bounds);

        const top = bounds.top + quillBounds.top + window.scrollY + 10;
        const left = bounds.left + quillBounds.left + window.scrollX;

        console.log("Calculated Top:", top);
        console.log("Calculated Left:", left);

        setPopupPosition({
          top: top,
          left: left,
        });
      }
    } else {
      setSelectedText("");
    }
  };
  console.log("selectedd image", selectedImage);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };
  // Custom handler for image upload

  const [setting, setSetting] = useState({
    brightness: 100,
    contrast: 100,
    saturation: 100,
    grayscale: 0,
    blur: 0,
    sepia: 0,
    hueRotate: 0,
    invert: 0,
    opacity: 100,
  });

  useEffect(() => {
    if (selectedImage && quillRef.current) {
      setSelectedButton("");
      setSelectedSetting("");
      const quill = quillRef.current.getEditor();
      const decodedSelectedImage = decodeURIComponent(selectedImage);
      const imgElement = quill.root.querySelector(`img[src="${decodedSelectedImage}"]`);
console.log("imgElement",imgElement);
      if (imgElement) {
        console.log("Applying settings:", setting);
    
        // Apply settings to the selected image
        imgElement.style.filter = `
          brightness(${setting.brightness}%) 
          contrast(${setting.contrast}%) 
          saturate(${setting.saturation}%) 
          grayscale(${setting.grayscale}%) 
          blur(${setting.blur}px) 
          sepia(${setting.sepia}%) 
          hue-rotate(${setting.hueRotate}deg) 
          invert(${setting.invert}%)`;
        imgElement.style.opacity = `${setting.opacity}%`;

        console.log("Settings applied to image:", imgElement);
      }
    }
  }, [setting, selectedImage]); // Notice I'm using `setting` instead of `settings` here

  const handleSettingChange = (e) => {
    const { name, value } = e.target;

    // Update the settings state
    setSetting((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));

    // Apply the changes immediately to avoid delays
    if (quillRef.current && selectedImage) {
      const quill = quillRef.current.getEditor();
      const imgElement = quill.root.querySelector(
        `img[src="${selectedImage}"]`
      );

      if (imgElement) {
        imgElement.style[name] = value; // Apply changes directly to the image element
      }
    }
  };
  const loadImages = async () => {
    try {
      setOpen(true);
      const response = await GetImages();
      if (response.success !== false) {
        const urls = response.result
          .sort((a, b) => new Date(b.created_date) - new Date(a.created_date)) // Sort by created_date (descending)
          .map((image) => image.image_url); // Extract image URLs
        setImages(urls);
      } else {
        funError(response.message);
      }
    } catch (err) {
      funError("Failed to fetch images");
    } finally {
      setOpen(false);
    }
  };

  // Fetch images on component mount or rerender
  useEffect(() => {
    loadImages();
  }, []);
  const handleNavigation = (data) => {
    if (data) {
      console.log("pop", pop);

      setback(true);

      console.log("show", show);
      console.log("download", download);
    } else {
      navigate(`/CreateProject/Blogposter/${templateId}`);
      setback(false); // Replace with the path you want to navigate to
    }
  };
  // Fetch roles once when the component mounts
  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const rolesData = await GetBlogRoles();

      if (rolesData.success) {
        setRoles(rolesData.roles);

        // Set the first role's data as default
        const firstRole = rolesData.roles[0];
        setSelectedRole(firstRole?.id);
        setTemperature(firstRole?.temperature);
        setFixedDescription(firstRole?.description);
      } else {
        funError("Error fetching roles");
      }
    } catch (error) {
      funError("Error fetching roles:", error);
    }
  };

  // Handle role change from dropdown
  const handleRoleChange = (e) => {
    const selectedRoleId = e.target.value;

    // Find the selected role from the roles list
    const selectedRole = roles.find((role) => role.id === selectedRoleId);

    setSelectedRole(selectedRoleId);
    setTemperature(selectedRole?.temperature);
    setFixedDescription(selectedRole?.description);
  };

  // Handle Add Role button click
  const handleAddRoleClick = () => {
    setIsAddingRole(true);
    setCustomRole(""); // Reset custom role input
    setDescription(""); // Reset description for custom role
    setTemperature(0.5); // Default value for temperature slider
  };

  // Handle Submit button click (send data to backend)
  const handleSubmitClick = async () => {
    if (isAddingRole) {
      const data = {
        role_name: customRole,
        temperature,
        description: description,
      };

      try {
        setOpen(true);
        const response = await PostAddrole(data);

        if (response.success) {
          // Reset fields after successful submissio

          setIsAddingRole(false);
          setSelectedButton("text");
          fetchRoles();
        } else {
          funError("Error submitting data");
        }
        setOpen(false);
      } catch (error) {
        funError("Error during API call:", error);
      }
    } else {
      setSelectedSetting("");
      setSelectedButton("text");
    }
  };

  // Handle Cancel button click (revert to initial state)
  const handleCancelClick = () => {
    setIsAddingRole(false); // Disable custom role input
    setCustomRole(""); // Clear custom role input

    // Re-fetch the selected role's temperature and description
    const selectedRoleData = roles.find((role) => role.id === selectedRole);
    setTemperature(selectedRoleData?.temperature);
    setFixedDescription(selectedRoleData?.description);
  };

  const [selectedPlatform, setSelectedPlatform] = useState(null);

  // Function to open the modal with respective platform's form
  const handleIconClick = (platform) => {
    setSelectedPlatform(platform);
  };

  // Function to close the modal
  const handleClose = () => {
    setSelectedPlatform(null);
    setFile(null);
    setTo("");
    setSender("");
    setSubject("");
    setIntegrationToken("");
    setTags([]);
    setTagInput("");
  };
  const fileInputRef = useRef(null);
  const savedRangeRef = useRef(null);

  // Save the current cursor position before the file input is clicked

  const handleuploadButtonClick = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.focus(); // Ensure the editor is focused before saving the cursor position
      const range = quill.getSelection(); // Save the current cursor position

      // If there's no active selection, set the range to the end of the document
      if (range) {
        savedRangeRef.current = range; // Store the range in ref
        console.log("Saved cursor position:", savedRangeRef.current);
      } else {
        savedRangeRef.current = { index: quill.getLength(), length: 0 }; // Default to end of content
        console.log(
          "No active selection, cursor set to end of the document:",
          savedRangeRef.current
        );
      }
    }
    console.log("dgfd");
    // Trigger the hidden file input to select an image
    fileInputRef.current.click();
    console.log("doneneegfd");
  };

  // Handle the file upload and restore cursor position
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    console.log("de");
    if (!file) {
      console.error("No file selected");
      return;
    }

    if (!file.type.startsWith("image/")) {
      console.error("Selected file is not an image");
      return;
    }
    console.log("dfgdoutside");
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result;
      console.log("outside", savedRangeRef.current);
      // Restore the saved cursor position and insert the image
      if (quillRef.current && savedRangeRef.current) {
        const quill = quillRef.current.getEditor();
        quill.setSelection(savedRangeRef.current.index); // Restore the cursor to the saved position

        handleImageSelect(base64Image); // Insert the image
        console.log("Inserting image at:", savedRangeRef.current.index);
      } else {
        console.log("Saved range is null");
      }
    };

    reader.readAsDataURL(file); // Convert image to base64
  };

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];

  //   if (!file) {
  //     funError("No file selected");
  //     return;
  //   }

  //   if (file.type !== "application/json") {
  //     funError("Selected file is not a JSON file");
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     try {
  //       const jsonContent = JSON.parse(reader.result); // Try to parse the file content
  //       console.log("JSON content:", jsonContent);
  //     } catch (error) {
  //       funError("Invalid JSON file");
  //     }
  //   };

  //   reader.readAsText(file); // Read the file content as text
  // };
  // Insert the image at the restored cursor position
  const handleImageSelect = (imageUrl) => {
    console.log("Inserting image URL:", imageUrl);
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      if (range) {
        quill.insertEmbed(range.index, "image", imageUrl); // Insert the image
        console.log("Image inserted at index:", range.index);
      }
    }
  };
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [sender, setSender] = useState("");
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/json") {
      setFile(selectedFile);
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        setOpen(true);
        const response = await Savefile(formData);
        if (response.url) {
          setUrl(response.url);
        } else {
          funError(response.message);
        }
        setOpen(false);

        console.log("API response:", response);
      } catch (error) {
        console.error("Error sending query:", error);
      }
    } else {
      funError("Please upload a valid JSON file.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    if (!file || !sender || !to || !subject) {
      funError("Please fill all fields and upload a JSON file.");
      return;
    }

    const formData = {
      token_file_url: url,
      gmail_body: contentData,
      sender: sender,
      to: to,
      subject: subject,
    };

    try {
      setOpen(true);
      const response = await postGmail(formData);
      if (response.success) {
        handleClose();
      } else {
        funError(response.message);
      }
      setOpen(false);

      console.log("API Response:", response.data);
      // Handle successful response
    } catch (error) {
      funError("Error submitting Data:", error);
      // Handle error response
    }
  };

  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [integrationToken, setIntegrationToken] = useState("");
  const [publishStatus, setPublishStatus] = useState("draft");

  // Handle Enter key to add tag
  const handletagKeyDown = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      setTags([...tags, tagInput.trim()]);
      setTagInput(""); // Clear the input field
      e.preventDefault(); // Prevent form submission
    }
  };

  // Remove a tag
  const handleRemoveTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };
  function htmlToMarkdown(html) {
    let markdown = html;

    // Convert <h1> to #
    markdown = markdown.replace(/<h1>(.*?)<\/h1>/g, "# $1\n");

    // Convert <h2> to ##
    markdown = markdown.replace(/<h2>(.*?)<\/h2>/g, "## $1\n");

    // Convert <h3> to ###
    markdown = markdown.replace(/<h3>(.*?)<\/h3>/g, "### $1\n");

    // Convert <h4> to ####
    markdown = markdown.replace(/<h4>(.*?)<\/h4>/g, "#### $1\n");

    // Convert <p> to plain text
    markdown = markdown.replace(/<p>(.*?)<\/p>/g, "$1\n");

    // Convert <img> to markdown image syntax ![alt text](url) or ![](url) if alt is missing
    markdown = markdown.replace(
      /<img.*?src=['"](.*?)['"].*?(alt=['"](.*?)['"])?[^>]*>/g,
      (_, src, __, altText) => `![${altText || ""}](${src})`
    );

    // Convert <strong> or <b> to bold
    markdown = markdown.replace(/<(strong|b)>(.*?)<\/\1>/g, "**$2**");

    // Convert <em> or <i> to italics
    markdown = markdown.replace(/<(em|i)>(.*?)<\/\1>/g, "_$2_");

    // Convert <a> to markdown links [text](url)
    markdown = markdown.replace(
      /<a href=['"](.*?)['"](.*?)>(.*?)<\/a>/g,
      "[$3]($1)"
    );

    // Remove other HTML tags
    markdown = markdown.replace(/<\/?[^>]+(>|$)/g, "");

    return markdown;
  }

  const handleImageDownload = (imageUrl, index) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = `image-${index}.jpg`; // Adjust file extension if necessary
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  // Handle form submission
  const handlemediumSubmit = async (e) => {
    e.preventDefault();
    console.log("contentData", contentData);
    const markdownData = htmlToMarkdown(contentData);
    console.log("markdownData", markdownData);
    const formData = {
      medium_integration_token: integrationToken,
      blog_context: markdownData,
      blog_title: title,
      blog_tags: tags,
      publish_choice: publishStatus,
    };

    try {
      setOpen(true);
      const response = await PostMedium(formData);

      if (response.success) {
        // Handle success
        // alert('Form submitted successfully!');
        handleClose(); // Close the popup
      } else {
        // Handle errors

        funError(`Error: API error`);
      }
      setOpen(false);
    } catch (error) {
      funError(`An error occurred: ${error.message}`);
    }
  };

  const handleLinkedIn = async (e) => {
    const formData = {
      access_token: integrationToken,
      document_url: docurl,
      document_title: title,
    };

    try {
      setOpen(true);
      const response = await PostLinkeIn(formData);

      if (response.success) {
        // Handle success
        // alert('Form submitted successfully!');
        handleClose(); // Close the popup
      } else {
        // Handle errors

        funError(`Error: API error`);
      }
      setOpen(false);
    } catch (error) {
      funError(`An error occurred: ${error.message}`);
    }
  };

  const handleAddClick = async (e) => {
    setNewText(genresponse);
    setGenresponse("");
  };
  const handlecutClick = async (e) => {
    setGenresponse("");
  };

  // const clickYes = async (e) => {
  //   saveContentAPI();
  //   setpop(false);
  //   setshow(false);
  // };
  const clickNO = async (data) => {
    setpop(false);
    if (download) {
      console.log("pop show3", pop, show);

      setDownload(false);
      handleDownloadButtonClick();
    } else if (back) {
      handleNavigation();
    }
  };

  const clicked = async (e) => {
    setshow(false);
    setDownload(false);
    setback(false);
  };
  return (
    <div className={styles.parent_container}>
      <Charging open={open} />
      <Toast ref={toast} />

      {/* 1st Child: Row with 4-5 Buttons */}
      <div className={styles.button_row}>
        <button
          className={styles.btnclassblue}
          onClick={() => handleNavigation(pop)}
        >
          <img src={Back} alt="Back Icon" className={styles.icon} />
          Blog Preferences
        </button>
        <div className={styles.btnclassend}>
          <label className={styles.switch}>
            <input
              type="checkbox"
              checked={isDarkMode}
              onChange={toggleTheme}
            />
            <div className={styles.slider}>
              <img
                src={isDarkMode ? llight : dlight}
                alt={isDarkMode ? "Dark Mode Icon" : "Light Mode Icon"}
                className={styles.icon}
              />
              <img
                src={isDarkMode ? ddark : ldark}
                alt={isDarkMode ? "Dark Mode Icon 2" : "Light Mode Icon 2"}
                className={styles.icon}
              />
            </div>
          </label>
          {/* <button className={styles.btnclassblk} onClick={handleEditClick}>
            <img src={Edit} alt="Edit Icon" className={styles.icon} />
            Edit
          </button> */}

          <button onClick={saveContentAPI} className={styles.btnclassblk}>
            <img src={Save} alt="Save Icon" className={styles.icon} />
            Save
          </button>
          <button
            onClick={() => handleDownloadButtonClick(pop)}
            className={styles.btnclassblue}
          >
            <img src={Download} alt="Download Icon" className={styles.icon} />
            Download
          </button>
          <div className={styles.buttonWrapper}>
            <button className={styles.btnclassblue}>Publish through</button>
            <div className={styles.iconsWrapper}>
              <img
                src={mediumIcon}
                alt="Medium"
                className={styles.styleImage}
                onClick={() => handleIconClick("Medium")}
              />
              <img
                src={linkedinIcon}
                alt="LinkedIn"
                className={styles.styleImage}
                onClick={() => handleIconClick("LinkedIn")}
              />
              <img
                src={gmailIcon}
                alt="Gmail"
                className={styles.styleImage}
                onClick={() => handleIconClick("Gmail")}
              />

              <img
                src={outlookIcon} // Replace with the correct icon
                alt="Outlook"
                className={`${styles.styleImage} ${styles.disabledImage}`} // Add disabled class conditionally
                onClick={() => {}} // Disable the click event
                style={{ cursor: "not-allowed" }} // Add inline styles for a visual cue
              />
            </div>
          </div>
        </div>
      </div>
      {selectedPlatform && <div className={styles.overlay} />}
      {selectedPlatform && (
        <div className={styles.popupModal}>
          <div className={styles.popupContent}>
            <button onClick={handleClose} className={styles.closeButton}>
              X
            </button>
            {selectedPlatform === "Medium" && (
              <div>
                <h5 className={styles.popupHeading}>Medium</h5>

                <form onSubmit={handlemediumSubmit}>
                  <label className={styles.formLabel}>Integration Token:</label>
                  <input
                    type="text"
                    className={styles.formInput}
                    value={integrationToken}
                    onChange={(e) => setIntegrationToken(e.target.value)}
                  />

                  <label className={styles.formLabel}>Tags:</label>
                  <input
                    type="text"
                    className={styles.formInput}
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    onKeyDown={handletagKeyDown}
                    placeholder="Enter a tag and press Enter"
                  />

                  {/* Display the tags */}
                  <div className={styles.tagContainer}>
                    {tags.map((tag, index) => (
                      <div key={index} className={styles.tag}>
                        {tag}
                        <span
                          className={styles.removeTag}
                          onClick={() => handleRemoveTag(index)}
                        >
                          &#10005;
                        </span>
                      </div>
                    ))}
                  </div>

                  <label className={styles.formLabel}>Publish Status:</label>
                  <div>
                    <select
                      className={styles.formSelect}
                      value={publishStatus}
                      onChange={(e) => setPublishStatus(e.target.value)}
                    >
                      <option value="draft">Draft</option>
                      <option value="public">Public</option>
                    </select>
                  </div>

                  <div className={styles.buttonGroup}>
                    <button type="submit" className={styles.btnclassblue}>
                      Submit
                    </button>
                    <button
                      type="button"
                      className={styles.cancelButton}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            )}
            {selectedPlatform === "LinkedIn" && (
              <div>
                <h5 className={styles.popupHeading}>LinkedIn</h5>
                <label className={styles.formLabel}>Access Token:</label>
                <input
                  type="text"
                  className={styles.formInput}
                  onChange={(e) => setIntegrationToken(e.target.value)}
                  value={integrationToken}
                />
                <div className={styles.buttonGroup}>
                  <button
                    onClick={handleLinkedIn}
                    className={styles.btnclassblue}
                  >
                    Submit
                  </button>
                  <button onClick={handleClose} className={styles.cancelButton}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {selectedPlatform === "Gmail" && (
              <form onSubmit={handleSubmit}>
                <h5 className={styles.popupHeading}>Gmail</h5>
                <label className={styles.formLabel}>
                  Upload a token.json file:
                </label>
                <input
                  type="file"
                  className={styles.fileUpload}
                  accept=".json"
                  onChange={handleFileUpload}
                />
                <label className={styles.formLabel}>Sender:</label>
                <input
                  type="text"
                  className={styles.formInput}
                  value={sender}
                  onChange={(e) => setSender(e.target.value)}
                />
                <label className={styles.formLabel}>To:</label>
                <input
                  type="text"
                  className={styles.formInput}
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
                <label className={styles.formLabel}>Subject:</label>
                <input
                  type="text"
                  className={styles.formInput}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <div className={styles.buttonGroup}>
                  <button type="submit" className={styles.btnclassblue}>
                    Submit
                  </button>
                  <button onClick={handleClose} className={styles.cancelButton}>
                    Cancel
                  </button>
                </div>
              </form>
            )}
            {selectedPlatform === "Outlook" && (
              <div>
                <h3>Outlook is disabled</h3>
              </div>
            )}
          </div>
        </div>
      )}

      {show && (download || back) && <div className={styles.overlay} />}
      {show && (download || back) && (
        <div className={styles.overlay}>
          <div className={styles.popupModal}>
            <div className={styles.popupContent}>
              <button onClick={clicked} className={styles.closeButton}>
                X
              </button>

              <label className={styles.formLabel}>
                Do you want to save changes?
              </label>

              <div className={styles.buttonGroup}>
                <button
                  onClick={saveContentAPI}
                  className={styles.btnclassblue}
                >
                  Yes
                </button>
                <button
                  onClick={() => clickNO(pop)}
                  className={styles.cancelButton}
                >
                  NO
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 2nd Child: Column with 2 Elements */}
      <div className={styles.column_container}>
        <div className={styles.column_item}>
          {/* ReactQuill Editor
          {/* <CustomToolbar/> */}
          <QuillEditor
            isDarkMode={isDarkMode}
            onSelectionChange={handleSelectionChange}
            setpop={setpop}
            // imageHandler={imageHandler}
            quillRef={quillRef} // Pass the ref to the child component
            setSelectedImage={setSelectedImage}
            editorContent={contentData}
            setEditorContent={setContentData}
            newText={newText}
            replaceText={replaceText}
            setReplaceText={setReplaceText}
            ref={quillEditorRef}
          />
          {selectedText && (
            <div
              className={styles.popupIcon}
              style={{
                position: "absolute",
                top: `${popupPosition.top}px`,
                left: `${popupPosition.left - 25}px`,
                zIndex: 1000,

                cursor: "pointer",
              }}
            >
              <div onClick={handleClick} className={styles.iconContainer}>
                <img src={magic} alt="icon" className={styles.iconImagenew} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.column_item2}>
          <div className={styles.variablediv}>
            {selectedSetting === "text" && (
              <div className={styles.textSetting}>
                <div className={styles.aligntxt}>Text-to-Text:</div>
                <div className={styles.settingsBox}>
                  {/* Role Selection or Custom Role Input */}
                  <div className={styles.fieldRow}>
                    <div className={styles.fieldGroup}>
                      <label className={styles.label}>Actor Role</label>
                      <div className={styles.actorRoleWrapper}>
                        {isAddingRole ? (
                          <input
                            type="text"
                            className={styles.inputField}
                            value={customRole}
                            onChange={(e) => setCustomRole(e.target.value)}
                            placeholder="Enter custom role"
                          />
                        ) : (
                          <select
                            className={styles.dropdownField}
                            value={selectedRole}
                            onChange={handleRoleChange}
                          >
                            {roles.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role["role_name"]}
                              </option>
                            ))}
                          </select>
                        )}

                        <button
                          className={styles.addRoleButton}
                          onClick={handleAddRoleClick}
                        >
                          + Add Role
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Temperature Slider */}
                  <div className={styles.fieldGroup}>
                    <label className={styles.label}>Temperature</label>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      className={styles.temperatureSlider}
                      value={temperature}
                      onChange={(e) => setTemperature(e.target.value)}
                      disabled={!isAddingRole} // Disable temperature editing if not adding a new role
                    />
                  </div>
                  {/* Description */}
                  <div className={styles.fieldGroup}>
                    <label className={styles.label}>Description</label>
                    {isAddingRole ? (
                      <textarea
                        className={styles.textareaField}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    ) : (
                      <div className={styles.textareaField}>
                        {fixedDescription}
                      </div>
                    )}
                  </div>
                  {/* Submit and Cancel Buttons */}{" "}
                  {isAddingRole && (
                    <div className={styles.buttonGroup}>
                      <button
                        className={styles.btnclassblue}
                        onClick={handleSubmitClick}
                      >
                        Submit
                      </button>
                      <button
                        className={styles.btnclassblk}
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}

            {selectedSetting === "image" && (
              <div className={styles.textSetting}>
                <div className={styles.aligntxt}>Text-to-Image:</div>
                <div className={styles.settingsBox}>
                  <div className={styles.fieldRow}>
                    <div className={styles.fieldGroup}>
                      <label className={styles.label}>Image size</label>
                      <select
                        className={styles.dropdownField}
                        onChange={handleImageSizeChange}
                      >
                        <option value="1024x1024">1024 x 1024</option>
                        <option value="1024x1792">1024 x 1792</option>
                        <option value="1792x1024">1792 x 1024</option>
                      </select>
                    </div>
                  </div>

                  {/* Style Category */}
                  <div className={styles.category}>
                    <div className={styles.categoryTitle}>Style</div>
                    <div className={styles.imageGrid}>
                      <div
                        className={`${styles.imageItem} ${
                          style === "None" ? styles.selected : ""
                        }`}
                        onClick={() => handleStyleChange("None")}
                      >
                        <img
                          src={none}
                          alt="None"
                          className={styles.styleImage}
                        />
                        <div className={styles.imageLabel}>None</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          style === "Anime" ? styles.selected : ""
                        }`}
                        onClick={() => handleStyleChange("Anime")}
                      >
                        <img
                          src={Anime}
                          alt="Anime"
                          className={styles.styleImage}
                        />
                        <div className={styles.imageLabel}>Anime</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          style === "Bokeh" ? styles.selected : ""
                        }`}
                        onClick={() => handleStyleChange("Bokeh")}
                      >
                        <img
                          src={Bokeh}
                          alt="Bokeh"
                          className={styles.styleImage}
                        />
                        <div className={styles.imageLabel}>Bokeh</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          style === "Cenematic" ? styles.selected : ""
                        }`}
                        onClick={() => handleStyleChange("Cenematic")}
                      >
                        <img
                          src={Cenematic}
                          alt="Cenematic"
                          className={styles.styleImage}
                        />
                        <div className={styles.imageLabel}>Cenematic</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          style === "Line art" ? styles.selected : ""
                        }`}
                        onClick={() => handleStyleChange("Line art")}
                      >
                        <img
                          src={LineArt}
                          alt="Line art"
                          className={styles.styleImage}
                        />
                        <div className={styles.imageLabel}>Line Art</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          style === "Oil Painting" ? styles.selected : ""
                        }`}
                        onClick={() => handleStyleChange("Oil Painting")}
                      >
                        <img
                          src={OilPainting}
                          alt="Oil Painting"
                          className={styles.styleImage}
                        />
                        <div className={styles.imageLabel}>Oil Painting</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          style === "Graphic" ? styles.selected : ""
                        }`}
                        onClick={() => handleStyleChange("Graphic")}
                      >
                        <img
                          src={Graphic}
                          alt="Graphic"
                          className={styles.styleImage}
                        />
                        <div className={styles.imageLabel}>Graphic</div>
                      </div>

                      {/* Add more images for Style */}
                    </div>
                  </div>

                  {/* Color Theme Category */}
                  <div className={styles.category}>
                    <div className={styles.categoryTitle}>Color Theme</div>
                    <div className={styles.imageGrid}>
                      <div
                        className={`${styles.imageItem} ${
                          colorTheme === "None" ? styles.selected : ""
                        }`}
                        onClick={() => handleColorThemeChange("None")}
                      >
                        <img
                          src={Demoimg}
                          alt="none Tone"
                          className={`${styles.styleImage} ${styles.noneColor}`}
                        />
                        <div className={styles.imageLabel}>None</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          colorTheme === "Warm Tone" ? styles.selected : ""
                        }`}
                        onClick={() => handleColorThemeChange("Warm Tone")}
                      >
                        <img
                          src={Demoimg}
                          alt="Warm Tone"
                          className={`${styles.styleImage} ${styles.warmTone}`}
                        />
                        <div className={styles.imageLabel}>Warm tone</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          colorTheme === "Cool Tone" ? styles.selected : ""
                        }`}
                        onClick={() => handleColorThemeChange("Cool Tone")}
                      >
                        <img
                          src={Demoimg}
                          alt="Cool Tone"
                          className={`${styles.styleImage} ${styles.coolTone}`}
                        />
                        <div className={styles.imageLabel}>Cool tone</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          colorTheme === "Muted Colors" ? styles.selected : ""
                        }`}
                        onClick={() => handleColorThemeChange("Muted Colors")}
                      >
                        <img
                          src={Demoimg}
                          alt="Muted Colors"
                          className={`${styles.styleImage} ${styles.mutedColors}`}
                        />
                        <div className={styles.imageLabel}>Muted</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          colorTheme === "Vibrant Colors" ? styles.selected : ""
                        }`}
                        onClick={() => handleColorThemeChange("Vibrant Colors")}
                      >
                        <img
                          src={Demoimg}
                          alt="Vibrant Colors"
                          className={`${styles.styleImage} ${styles.vibrantColors}`}
                        />
                        <div className={styles.imageLabel}>Vibrant</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          colorTheme === "Pastel Colors" ? styles.selected : ""
                        }`}
                        onClick={() => handleColorThemeChange("Pastel Colors")}
                      >
                        <img
                          src={Demoimg}
                          alt="Pastel Colors"
                          className={`${styles.styleImage} ${styles.pastelColors}`}
                        />
                        <div className={styles.imageLabel}>Pastel</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          colorTheme === "Black and White"
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() =>
                          handleColorThemeChange("Black and White")
                        }
                      >
                        <img
                          src={Demoimg}
                          alt="Black and White"
                          className={`${styles.styleImage} ${styles.blackAndWhite}`}
                        />
                        <div className={styles.imageLabel}>Black & White </div>
                      </div>

                      {/* Add more images for Color Theme */}
                    </div>
                  </div>

                  {/* Lighting Style Category */}
                  <div className={styles.category}>
                    <div className={styles.categoryTitle}>Lighting Style</div>
                    <div className={styles.imageGrid}>
                      <div
                        className={`${styles.imageItem} ${
                          lightingStyle === "None" ? styles.selected : ""
                        }`}
                        onClick={() => handleLightingStyleChange("None")}
                      >
                        <img
                          src={Demoimg}
                          alt="None"
                          className={`${styles.styleImage} ${styles.noneLight}`}
                        />
                        <div className={styles.imageLabel}>None</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          lightingStyle === "Natural Light"
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() =>
                          handleLightingStyleChange("Natural Light")
                        }
                      >
                        <img
                          src={Demoimg}
                          alt="Natural Light"
                          className={`${styles.styleImage} ${styles.naturalLight}`}
                        />
                        <div className={styles.imageLabel}>Natural</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          lightingStyle === "Back Lighting"
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() =>
                          handleLightingStyleChange("Back Lighting")
                        }
                      >
                        <img
                          src={Demoimg}
                          alt="Back Lighting"
                          className={`${styles.styleImage} ${styles.backLighting}`}
                        />
                        <div className={styles.imageLabel}>Back light</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          lightingStyle === "Low Light" ? styles.selected : ""
                        }`}
                        onClick={() => handleLightingStyleChange("Low Light")}
                      >
                        <img
                          src={Demoimg}
                          alt="Low Light"
                          className={`${styles.styleImage} ${styles.lowLight}`}
                        />
                        <div className={styles.imageLabel}>Low light</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          lightingStyle === "Sun Light" ? styles.selected : ""
                        }`}
                        onClick={() => handleLightingStyleChange("Sun Light")}
                      >
                        <img
                          src={Demoimg}
                          alt="Sun Light"
                          className={`${styles.styleImage} ${styles.sunLight}`}
                        />
                        <div className={styles.imageLabel}>Sunlight</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          lightingStyle === "Ambient Light"
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() =>
                          handleLightingStyleChange("Ambient Light")
                        }
                      >
                        <img
                          src={Demoimg}
                          alt="Ambient Light"
                          className={`${styles.styleImage} ${styles.ambientLight}`}
                        />
                        <div className={styles.imageLabel}>Ambient</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          lightingStyle === "Broad Lighting"
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() =>
                          handleLightingStyleChange("Broad Lighting")
                        }
                      >
                        <img
                          src={Demoimg}
                          alt="Broad Lighting"
                          className={`${styles.styleImage} ${styles.broadLighting}`}
                        />
                        <div className={styles.imageLabel}>Broad </div>
                      </div>
                      {/* Add more images for Lighting Style */}
                    </div>
                  </div>

                  {/* Composition Category */}
                  <div className={styles.category}>
                    <div className={styles.categoryTitle}>Composition</div>
                    <div className={styles.imageGrid}>
                      <div
                        className={`${styles.imageItem} ${
                          composition === "None" ? styles.selected : ""
                        }`}
                        onClick={() => handleCompositionChange("None")}
                      >
                        <img
                          src={Demoimg}
                          alt="None"
                          className={`${styles.styleImage} ${styles.noneComposition}`}
                        />
                        <div className={styles.imageLabel}>None</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          composition === "closeUp" ? styles.selected : ""
                        }`}
                        onClick={() => handleCompositionChange("closeUp")}
                      >
                        <img
                          src={Demoimg}
                          alt="closeUp"
                          className={`${styles.styleImage} ${styles.closeUp}`}
                        />
                        <div className={styles.imageLabel}>Close-up</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          composition === "longShot" ? styles.selected : ""
                        }`}
                        onClick={() => handleCompositionChange("longShot")}
                      >
                        <img
                          src={Demoimg}
                          alt="longShot"
                          className={`${styles.styleImage} ${styles.longShot}`}
                        />
                        <div className={styles.imageLabel}>Long</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          composition === "overhead" ? styles.selected : ""
                        }`}
                        onClick={() => handleCompositionChange("overhead")}
                      >
                        <img
                          src={Demoimg}
                          alt="overhead"
                          className={`${styles.styleImage} ${styles.overhead}`}
                        />
                        <div className={styles.imageLabel}>Overhead</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          composition === "aerial" ? styles.selected : ""
                        }`}
                        onClick={() => handleCompositionChange("aerial")}
                      >
                        <img
                          src={Demoimg}
                          alt="aerial"
                          className={`${styles.styleImage} ${styles.aerial}`}
                        />
                        <div className={styles.imageLabel}>Aerial</div>
                      </div>
                      <div
                        className={`${styles.imageItem} ${
                          composition === "lowAngle" ? styles.selected : ""
                        }`}
                        onClick={() => handleCompositionChange("lowAngle")}
                      >
                        <img
                          src={Demoimg}
                          alt="lowAngle"
                          className={`${styles.styleImage} ${styles.lowAngle}`}
                        />
                        <div className={styles.imageLabel}>Low angle</div>
                      </div>
                      {/* Add more images for Composition */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedImage && (
              <div>
                <div className={styles.sliders}>
                  <div className={styles.sliderRow}>
                    <label>Brightness:</label>
                    <input
                      type="range"
                      name="brightness"
                      min="0"
                      max="200"
                      value={setting.brightness}
                      onChange={handleSettingChange}
                    />
                  </div>
                  <div className={styles.sliderRow}>
                    <label>Contrast:</label>
                    <input
                      type="range"
                      name="contrast"
                      min="0"
                      max="200"
                      value={setting.contrast}
                      onChange={handleSettingChange}
                    />
                  </div>
                  <div className={styles.sliderRow}>
                    <label>Saturation:</label>
                    <input
                      type="range"
                      name="saturation"
                      min="0"
                      max="200"
                      value={setting.saturation}
                      onChange={handleSettingChange}
                    />
                  </div>
                  <div className={styles.sliderRow}>
                    <label>Grayscale:</label>
                    <input
                      type="range"
                      name="grayscale"
                      min="0"
                      max="100"
                      value={setting.grayscale}
                      onChange={handleSettingChange}
                    />
                  </div>
                  <div className={styles.sliderRow}>
                    <label>Blur:</label>
                    <input
                      type="range"
                      name="blur"
                      min="0"
                      max="10"
                      value={setting.blur}
                      onChange={handleSettingChange}
                    />
                  </div>
                  <div className={styles.sliderRow}>
                    <label>Sepia:</label>
                    <input
                      type="range"
                      name="sepia"
                      min="0"
                      max="100"
                      value={setting.sepia}
                      onChange={handleSettingChange}
                    />
                  </div>
                  <div className={styles.sliderRow}>
                    <label>Hue Rotate:</label>
                    <input
                      type="range"
                      name="hueRotate"
                      min="0"
                      max="360"
                      value={setting.hueRotate}
                      onChange={handleSettingChange}
                    />
                  </div>
                  <div className={styles.sliderRow}>
                    <label>Invert:</label>
                    <input
                      type="range"
                      name="invert"
                      min="0"
                      max="100"
                      value={setting.invert}
                      onChange={handleSettingChange}
                    />
                  </div>
                  <div className={styles.sliderRow}>
                    <label>Opacity:</label>
                    <input
                      type="range"
                      name="opacity"
                      min="0"
                      max="100"
                      value={setting.opacity}
                      onChange={handleSettingChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedSetting === null &&
              selectedButton === "text" &&
              genresponse && (
                <div className={styles.textSetting}>
                  <div className={styles.aligntxt}>Generated Response:</div>
                  <div className={styles.settingsBox}>
                    <div className={styles.textareaField}>{genresponse}</div>
                    {/* Submit and Cancel Buttons */}{" "}
                    {genresponse && (
                      <div className={styles.buttonGroup}>
                        <button
                          className={styles.btnclassblue}
                          onClick={handleAddClick}
                        >
                          Add
                        </button>
                        <button
                          className={styles.btnclassblk}
                          onClick={handlecutClick}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {selectedSetting === null && selectedButton === "image" && (
              <div>
                <h5>Generated Images </h5>
                <div className={styles.imageContainer}>
                  {images.map((image, index) => (
                    <img
                      src={image}
                      alt={`Generated ${index}`}
                      key={index}
                      onClick={() => handleImageSelect(image)}
                    />
                  ))}
                </div>
              </div>
            )}
            {isClicked && selectedText && (
              <div className={styles.iconClicked}>
                <div className={styles.row}>
                  <img src={rewrite} alt="icon" className={styles.iconImage} />
                  <div onClick={() => setReplaceCondition("rephrase")}>
                    Rephrase
                  </div>
                </div>
                <div className={styles.row}>
                  <img src={summary} alt="icon" className={styles.iconImage} />
                  <div onClick={() => setReplaceCondition("summarize")}>
                    Summarize
                  </div>
                </div>
                <div className={styles.row}>
                  <img src={refine} alt="icon" className={styles.iconImage} />
                  <div onClick={() => setReplaceCondition("user_preference")}>
                    Refine with prompt
                  </div>
                </div>
                <div className={styles.row}>
                  <img src={compress} alt="icon" className={styles.iconImage} />
                  <div onClick={() => setReplaceCondition("compress")}>
                    Compress text
                  </div>
                </div>
                <div className={styles.row}>
                  <img
                    src={longwinded}
                    alt="icon"
                    className={styles.iconImage}
                  />
                  <div onClick={() => setReplaceCondition("long-winded")}>
                    Long winded
                  </div>
                </div>
              </div>
            )}

            {/* <Toolbar isVisible={isToolbarVisible} /> */}
            {/* Other conditions */}
          </div>

          <div className={styles.btnbig}>
            <button
              className={
                selectedButton === "text" || selectedSetting === "text"
                  ? styles.bigbtnclassblue
                  : styles.bigbtnclassblk
              }
              onClick={() => handleButtonClick("text")}
            >
              <div className={styles.texte}>Create-Text</div>
              <img
                src={settings}
                alt="settings Icon"
                className={styles.bigicon}
                onClick={(e) => handleSettingClick(e, "text")}
              />
            </button>

            <button
              className={
                selectedButton === "image" || selectedSetting === "image"
                  ? styles.bigbtnclassblue
                  : styles.bigbtnclassblk
              }
              onClick={() => handleButtonClick("image")}
            >
              <div className={styles.texte}>Create-Image</div>
              <img
                src={settings}
                alt="settings Icon"
                className={styles.bigicon}
                onClick={(e) => handleSettingClick(e, "image")}
              />
            </button>
          </div>
          {(selectedButton === "image" || selectedSetting === "image") && (
            <div className={styles.widthcss}>
              <div
                onClick={handleuploadButtonClick}
                className={`${styles.buttonBox} ${styles.margins}`}
              >
                <img src={imageupload} alt="upload Icon" />
                <span>upload image </span>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                style={{ display: "none" }} // Hidden file input
                onChange={handleImageUpload} // File selection handler
              />
              {/* <div className={styles.margins}>
                <img src={imageupload} alt="upload Icon" />
              </div> */}
              <div
                className={`${styles.buttonBox} ${
                  isDisabled ? styles.disabled : ""
                }`}
                onClick={!isDisabled ? toggleActive : null}
              >
                <img src={star} alt="Star Icon" />
                <span>Enhance prompt</span>
                <img
                  src={isActive ? act : inact}
                  alt={isActive ? "Active Toggle" : "Inactive Toggle"}
                />
              </div>
            </div>
          )}
          <div className={styles.input_bar}>
            <textarea
              style={{ minHeight: "45px", maxHeight: "85px", height: "45px" }}
              placeholder="Ask AI to generate..."
              onInput={autoResize} // Handle input changes
              onKeyDown={handleKeyDown} // Detect 'Enter' key press
              value={query} // Bind textarea value to state
            ></textarea>
            <button
              disabled={query.length === 0}
              onClick={handlesendButtonClick}
              style={{
                backgroundColor: "#212b46", // Corrected: background-color should be backgroundColor
                border: "none", // Already correct
                padding: "5px", // Already correct
                borderRadius: "5px", // Already correct
                cursor: query.length > 0 ? "pointer" : "not-allowed", // Already correct
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 36 36"
                fill="white" // Optional: change the arrow color
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="M8.60547 7.88672V15.5651L19.1154 17.8234L8.60547 20.0817V27.7601L26.8227 17.8234L8.60547 7.88672Z" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Poster;
