import React, { useRef, useState, useEffect } from "react";
import styles from "../../screens/ContentGeneration/Dashboard.module.css";
import card from "../../assests/images/Blogposter/card.png";
import toggle from "../../assests/images/Blogposter/toggle.png";
import { ContentdeleteProject, GetProject, PostProject } from "../../commonApi";
import Charging from "../../Charging";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const [optionsMenuIndex, setOptionsMenuIndex] = useState(null);

  const navigate = useNavigate();
  const handleProjectClick = (projectId) => {
    navigate(`/CreateProject/CreateTemplate/${projectId}`);
  };

  useEffect(() => {
    fetchProjects();
  }, []);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleCreateProject = async () => {
    if (newProjectName.trim() === "") {
      alert("Please enter a project name.");
      return;
    }

    const projectData = {
      project_name: newProjectName,
    };

    const response = await PostProject(projectData);
    try {
      if (response.success) {
        // After creating a new project, fetch the latest list of projects
        fetchProjects();
        setNewProjectName("");
        handleClosePopup();
      } else {
        showError(response.message);
      }
    } catch (error) {
      showError(error);

      console.error("Failed to Create projects:", error);
    }
  };

  const fetchProjects = async () => {
    setOpen(true);

    try {
      const response = await GetProject();

      if (response.success) {
        setProjects(response.projects);
      } else {
        console.log(response.message);
      }
    } catch (error) {
      showError(error);

      console.error("Failed to fetch projects:", error);
    }
    setOpen(false);
  };
  const toggleOptionsMenu = (index, event) => {
    event.stopPropagation();
    setOptionsMenuIndex(optionsMenuIndex === index ? null : index);
  };

  const handleDeleteProject = async (projectId, event) => {
    event.stopPropagation();
    const response = await ContentdeleteProject(projectId);
    if (response.success) {
      toast.current.show({
        severity: "success",
        summary: "Delete Success",
        detail: "Project deleted successfully",
        life: 3000,
      });

      fetchProjects(); // Refresh the list of projects
    } else {
      toast.current.show({
        severity: "error",
        summary: "Delete Failed",
        detail: response.message,
        life: 4000,
      });
    }
  };

  const showError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 4000,
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.dashboard}>
        <div className={styles.BOX}>
          <div className={styles.create_button} onClick={togglePopup}>
            <p>+ Create New Project</p>
          </div>
          {projects?.map((project, index) => (
            <div
              key={project.Project_unique_id}
              className={styles.project_card}
              onClick={() => handleProjectClick(project.Project_unique_id)}
            >
              <div className={styles.project_info}>
                <img
                  src={card}
                  alt="Project Icon"
                  className={styles.project_icon}
                />
                <h5>{project.Project_name}</h5>
              </div>
              <img
                src={toggle}
                className={styles.more_options_button}
                onClick={(event) => toggleOptionsMenu(index, event)}
              />
              {optionsMenuIndex === index && (
                <div className={styles.options_menu}>
                  <div
                    className={styles.edit_option}
                    // onClick={() => handleEditProject(index)}
                  >
                    Edit
                  </div>
                  <div
                    className={styles.delete_option}
                    onClick={(event) =>
                      handleDeleteProject(project.Project_unique_id, event)
                    }
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {showPopup && (
          <div className={styles.popupOverlay}>
            <div className={styles.popup}>
              <div className={styles.role}>
                <h6>Project Name</h6>
                <input
                  type="text"
                  className={styles.inputField}
                  placeholder="Enter Project Name"
                  value={newProjectName}
                  onChange={(e) => setNewProjectName(e.target.value)}
                />
                <div className={styles.modelButton}>
                  <button
                    type="submit"
                    onClick={handleCreateProject}
                    className={styles.access}
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    onClick={handleClosePopup}
                    className={styles.cancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
