import React, { useRef, useState, useEffect } from "react";
import styles from "../Optilogistics/Optilogistics.module.css";
import OptilogisticsSidebar from "./OptilogisticsSidebar";
import truck from "../../../assests/images/pluginicons/smalltruck.png";
import closebox from "../../../assests/images/pluginicons/closebox.png";
import message from "../../../assests/images/pluginicons/message.png";
import whatsapp from "../../../assests/images/pluginicons/whatsapp.png";
import send from "../../../assests/images/pluginicons/send.png";
import Edit from "../../../assests/images/pluginicons/edit.png";
import share from "../../../assests/images/pluginicons/share.png";
import cx from "classnames";
import {
  Delete_Delivery,
  EditTruck,
  GetRuns,
  PostEmail,
} from "../../../commonApi";
import Charging from "../../../Charging";
import { Toast } from "primereact/toast";

const Optilogistics = () => {
  const [openresponsePopup, setOpenResponsePopup] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [manifest, setManifest] = useState(null);
  const [selectedRunIds, setSelectedRunIds] = useState("");
  const [selectedManifest, setselectedManifest] = useState(null);
  const [selectedTruckId, setSelectedTruckId] = useState(null);
  const toast = useRef(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [activeRunId, setActiveRunId] = useState(null);
  const [fileNames, setFileNames] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [sessionName, setSessionName] = useState("New Job");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deliveryData, setDeliveryData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedBinPacking, setSelectedBinPacking] = useState(null);
  const [selectedIndices, setSelectedIndices] = useState([]);

  const fetchRuns = async () => {
    setOpen(true);
    const response = await GetRuns();
    if (response.success) {
    } else {
      console.error(response.message || "Failed to fetch runs");
    }
    setOpen(false);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (index) => {
    setSelectedIndices((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index); // Unselect if already selected
      } else {
        return [...prevSelected, index]; // Select if not selected
      }
    });
  };

  const handleDelete = async () => {
    const data = {
      run_id: activeRunId, // Assuming the run ID is available in the selectedManifest
      truck_id: selectedManifest?.vehicleId,
      indices: selectedIndices, // Indices selected by the user
    };

    try {
      setOpen(true);
      const response = await Delete_Delivery(data);
      if (response.success) {
        console.log("Deleted successfully", response);
        fetchRuns();
        setIsModalOpen(false);
        setSelectedIndices([]);

        // Optionally refresh the delivery data or close the modal
      } else {
        console.error("Delete failed:", response.message);
      }
    } catch (error) {
      console.error("Error while deleting:", error);
    }
    setOpen(false);
  };

  // const filteredManifests = manifest?.manifests?.filter((manifestItem) =>
  //   manifestItem.vehicleId.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const filteredManifests =
    manifest?.manifests?.filter((manifestItem) =>
      manifestItem.vehicleId.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  const handleFileNameChange = (fileName, runId) => {
    setFileNames((prev) => ({
      ...prev,
      [runId]: fileName,
    }));
  };

  const areRunsActive = () => {
    return manifest && manifest.manifests && manifest.manifests.length > 0;
  };
  const handleEditClick = async (manifestItem) => {
    // Prepare the data in the required format
    const data = {
      run_id: activeRunId, // Assuming `runId` is part of the `manifestItem`
      truck_id: manifestItem.vehicleId, // This is the truck ID
    };

    try {
      setOpen(true);
      // Call the API to modify deliveries
      const response = await EditTruck(data);
      console.log("API response:", response);
      if (response.success) {
        setDeliveryData(response.data); // Store the API data
      }

      // Handle response if needed (e.g., showing a success message or closing the modal)
    } catch (error) {
      console.error("Error while editing truck:", error);
    }
    setOpen(false);

    // Optionally, open the modal to allow further edits
    setselectedManifest(manifestItem);
    setIsModalOpen(true);
  };

  // console.log("-----------------runid", manifest);

  useEffect(() => {
    if (manifest?.manifests?.length > 0) {
      const firstTruckId = manifest.manifests[0].vehicleId;
      setSelectedTruckId(firstTruckId);

      // Set the manifest details for the first truck
      const manifestDetail = manifest.manifests.find(
        (item) => item.vehicleId === firstTruckId
      );
      setselectedManifest(manifestDetail);

      // Set the bin packing details for the first truck
      const binPackingDetail = manifest.bin_packing.find(
        (item) => item.truck_id === firstTruckId
      );
      setSelectedBinPacking(binPackingDetail);
    }
  }, [manifest]);

  // const selectedManifest =
  //   manifest?.manifests?.find((item) => item.vehicleId === selectedTruckId) ||
  //   null;

  useEffect(() => {
    // Code to trigger the dotLottie animation on component mount
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
    script.type = "module";
    document.body.appendChild(script);
  }, []);

  const handleEmailChange = (e) => {
    setEmailAddress(e.target.value);
  };
  const handleTruckSelect = (truckId) => {
    setSelectedTruckId(truckId);
    // Find and set the manifest details for the selected truck
    const manifestDetail = manifest.manifests.find(
      (item) => item.vehicleId === truckId
    );
    setselectedManifest(manifestDetail);

    // Find and set the bin packing details for the selected truck
    const binPackingDetail = manifest.bin_packing.find(
      (item) => item.truck_id === truckId
    );
    setSelectedBinPacking(binPackingDetail);
  };
  const handleSelectedRunIdsChange = (ids) => {
    setSelectedRunIds(ids);
    console.log("Updated Run IDs in parent:", ids);
  };

  const handleSend = async () => {
    // Check if a run ID and truck ID are selected
    if (!activeRunId) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please select a session name to send an email",
        life: 3000,
      });
      return;
    }

    const data = {
      email_id: emailAddress,
      run_id: activeRunId, // Assuming you want to send the first selected run ID
      truck_id: selectedTruckId,
    };

    try {
      setOpen(true);
      const response = await PostEmail(data);
      setDropdownVisible(false);
      setEmailAddress("");
      setOpen(false);

      if (response.success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Email sent successfully!",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.message || "Failed to send email",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to send email. Please try again.",
        life: 3000,
      });
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const toggleResponsePopup = () => {
    setOpenResponsePopup(!openresponsePopup);
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />

      <div className={styles.head}>
        <div className={styles.left}>
          <OptilogisticsSidebar
            sessionName={sessionName}
            onActiveRunIdChange={setActiveRunId}
            onManifestUpdate={setManifest}
            onSelectedRunIdsChange={handleSelectedRunIdsChange}
            onFileNameChange={handleFileNameChange}
            onSessionNameChange={setSessionName}
          />
        </div>

        <div className={styles.fixed}>
          <div className={styles.right}>
            <div className={styles.header}>{sessionName}</div>
          </div>
          {areRunsActive() && (
            <div className={styles.uploadname}>
              <h6>Uploaded file name :{fileNames[activeRunId]}</h6>
              <button className={styles.response} onClick={toggleResponsePopup}>
                API Response
              </button>
            </div>
          )}
          {!areRunsActive() && (
            <div className={styles.centerAnimation}>
              <script
                src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs"
                type="module"
              ></script>
              <dotlottie-player
                src="https://lottie.host/69f0ce1c-1f44-4f13-8784-4fa767aa3236/7fBlyUJk6C.json"
                background="transparent"
                speed="1"
                className={styles.dottie}
                loop
                autoplay
              ></dotlottie-player>
            </div>
          )}
          <div className={styles.just}>
            <div className={styles.middle}>
              {selectedTruckId && (
                <div className={styles.fullbox}>
                  <div className={styles.leftt}>
                    <div className={styles.truck}>
                      <h6 onClick={(e) => e.stopPropagation()}>
                        Truck ID: {selectedTruckId}
                      </h6>

                      <div className={styles.shareContainer}>
                        <img
                          src={share}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents truck deselection
                            toggleDropdown();
                          }}
                          alt="share icon"
                        />
                        {isDropdownVisible && (
                          <div
                            className={styles.dropdown}
                            onClick={(e) => e.stopPropagation()} // Prevents truck deselection
                          >
                            <div className={styles.dropdownSection}>
                              <div className={styles.message}>
                                <img src={message} />
                                <h6>Email</h6>
                              </div>
                              <div className={styles.inputWrapper}>
                                <input
                                  type="email"
                                  placeholder="email address"
                                  value={emailAddress}
                                  onChange={handleEmailChange}
                                  className={styles.inputField}
                                />
                                <img
                                  src={send}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevents truck deselection
                                    handleSend();
                                  }}
                                  className={styles.inputIcon}
                                />
                              </div>
                            </div>
                            <div className={styles.dropdownSection}>
                              <div className={styles.message}>
                                <img src={whatsapp} />
                                <h6>WhatsApp (upcoming)</h6>
                              </div>
                              {/* <input
                                type="number"
                                placeholder="Phone Number"
                                className={styles.inputField}
                              /> */}
                            </div>
                            {/* <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents truck deselection
                                handleSend();
                              }}
                              className={styles.sendButton}
                            >
                              <img src={send} />
                            </button> */}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.texarea}>
                      {manifest.manifests.find(
                        (item) => item.vehicleId === selectedTruckId
                      )?.manifest
                        ? manifest.manifests
                            .find((item) => item.vehicleId === selectedTruckId)
                            .manifest.split("\n")
                            .map((line, idx) => (
                              <React.Fragment key={idx}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                        : "No manifest details available for this truck"}
                    </div>
                  </div>

                  <div className={styles.rightmaps}>
                    {selectedManifest?.gmapsLink ? (
                      <iframe
                        width="600"
                        height="450"
                        loading="lazy"
                        allowFullScreen
                        src={selectedManifest.gmapsLink}
                      ></iframe>
                    ) : (
                      <p>No map available for this truck</p>
                    )}
                  </div>
                </div>
              )}

              {selectedBinPacking && (
                <div>
                  <div className={styles.container}>Container details</div>
                  {selectedBinPacking.bins &&
                    selectedBinPacking.bins.length > 0 && (
                      <div className={styles.length}>
                        <h6>length: {selectedBinPacking.bins[0].length}</h6>
                        <h6>width: {selectedBinPacking.bins[0].width}</h6>
                        <h6>height: {selectedBinPacking.bins[0].height}</h6>
                        <h6>
                          total items: {selectedBinPacking.bins[0].total_items}
                        </h6>
                        <h6>
                          filling ratio:{" "}
                          {selectedBinPacking.bins[0].filling_ratio}
                        </h6>
                      </div>
                    )}
                  <div className={styles.container}>Item details</div>
                  <div className={styles.itemDetails}>
                    <table className={styles.itemTable}>
                      <thead>
                        <tr>
                          <th>Item Name</th>
                          <th>Length</th>
                          <th>Width</th>
                          <th>Height</th>
                          <th>Position X</th>
                          <th>Position Y</th>
                          <th>Position Z</th>
                          <th>Rotation Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedBinPacking.bins &&
                          selectedBinPacking.bins[0].fitted_items.map(
                            (item, itemIndex) => (
                              <tr key={itemIndex}>
                                <td>{item.name}</td>
                                <td>{item.length} m</td>
                                <td>{item.width} m</td>
                                <td>{item.height} m</td>
                                <td>{item.position[0]}</td>
                                <td>{item.position[1]}</td>
                                <td>{item.position[2]}</td>
                                <td>{item.rotation_type}</td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
            {areRunsActive() && (
              <div className={styles.truckdetails}>
                <div className={styles.searchContainer}>
                  <input
                    className={styles.search}
                    type="text"
                    placeholder="Search by Truck ID"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <i
                    className={cx(
                      "fa fa-solid fa-magnifying-glass",
                      styles.searchabs
                    )}
                  ></i>
                </div>
                {filteredManifests?.map((manifestItem, index) => (
                  <div
                    key={index}
                    className={`${styles.truckList} ${
                      selectedTruckId === manifestItem.vehicleId
                        ? styles.active
                        : ""
                    }`}
                    onClick={() => handleTruckSelect(manifestItem.vehicleId)}
                  >
                    <h6>
                      <img src={truck} alt="Truck" />
                      Truck ID: {manifestItem.vehicleId || "Unknown"}
                      <div
                        className={styles.Edit}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the click from triggering truck selection
                          handleEditClick(manifestItem); // Open modal and pass data
                        }}
                      >
                        <img src={Edit} alt="Edit" />
                      </div>
                    </h6>
                  </div>
                ))}
                {isModalOpen && (
                  <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                      <div className={styles.clo}>
                        <h6>
                          Modify Deliveries For Truck ID:
                          <h6 className={styles.truck}>
                            {selectedManifest?.vehicleId}
                          </h6>
                        </h6>
                        <img
                          src={closebox}
                          className={styles.close}
                          onClick={() => setIsModalOpen(false)}
                          alt="Close"
                        />
                      </div>

                      {/* Table to display API data */}
                      <table className={styles.asd}>
                        <thead>
                          <tr>
                            <th>Select</th>
                            <th>Place</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                          </tr>
                        </thead>

                        <tbody>
                          {deliveryData.map((item, index) => (
                            <tr key={item.index}>
                              <td>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(item.index)
                                  } // Track selection
                                  checked={selectedIndices.includes(item.index)} // Check if selected
                                />
                              </td>
                              <td>{item.place}</td>
                              <td>{item.lat}</td>
                              <td>{item.lng}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <button
                        className={styles.deleteButton}
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {openresponsePopup && (
        <div className={styles.overlay}>
          <div className={styles.popup}>
            <h6>
              API Response
              <img src={closebox} onClick={toggleResponsePopup} alt="Close" />
            </h6>
          </div>

          <div className={styles.content}>
            <pre>
              {manifest
                ? JSON.stringify(manifest, null, 2)
                : "No manifest data available."}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default Optilogistics;
