import React, { useState, useEffect, useCallback, useImperativeHandle, forwardRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./blogSetting.module.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { marked } from "marked";

const QuillEditor = forwardRef(({
  isDarkMode,
  onSelectionChange,
  quillRef,
  setSelectedImage,
  editorContent,
  setEditorContent,
  newText,
  replaceText,
  setReplaceText,
  setpop
}, ref) => {
  const [cursorPosition, setCursorPosition] = useState(null);
  const [selectionRange, setSelectionRange] = useState(null);



  useEffect(() => {
    const savedContent = localStorage.getItem("editorContent");
    if (savedContent) {
      setEditorContent(savedContent);
    }
  }, [setEditorContent]);

  useEffect(() => {
    localStorage.setItem("editorContent", editorContent || "");
  }, [editorContent]);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      console.log("editorcontent--",editorContent);
      const htmlContent = editorContent || "";
      console.log("htmlcontent--",htmlContent);
// const htmlContent = editorContent 
//         ? marked(editorContent
//             .replace(/^# (.*$)/gim, '<h1>$1</h1>')   // Convert # text to <h1>
//             .replace(/^## (.*$)/gim, '<h2>$1</h2>')  // Convert ## text to <h2>
//             .replace(/\*\*(.*?)\*\*/g, '<h3>$1</h3>') // Convert **text** to <h3>
//           )
//         : "";
      if (quill.root.innerHTML !== htmlContent) {
        quill.root.innerHTML = htmlContent;
      }
    }
  }, [editorContent, quillRef]);



  const handleEditorChange = useCallback((content, delta, source, editor) => {
    setEditorContent(content);
    setpop(true);
    if (source === "user") {
      setEditorContent(content);
      setCursorPosition(editor.getSelection()?.index || null);
    }
  }, [setEditorContent]);

  const handleSelectionChange = useCallback(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();
      if (range) {
        const selectedText = quill.getText(range.index, range.length);
        onSelectionChange(selectedText, range);
        setCursorPosition(range.index);
        setSelectionRange(range);
      }
    }
  }, [onSelectionChange]);

  const replaceTextInSelection = useCallback(() => {
    if (quillRef.current && replaceText && selectionRange) {
      const quill = quillRef.current.getEditor();
      quill.deleteText(selectionRange.index, selectionRange.length);
      quill.insertText(selectionRange.index, replaceText);
      setReplaceText("");
      setSelectionRange(null);
    }
  }, [replaceText, setReplaceText, selectionRange, quillRef]);

  useEffect(() => {
    replaceTextInSelection();
  }, [replaceText, replaceTextInSelection]);

  const insertTextAtCursor = useCallback((text) => {
   if (quillRef.current && cursorPosition !== null && text) {
    const quill = quillRef.current.getEditor();
    quill.focus(); // Ensure the editor is focused before inserting text
    const range = quill.getSelection();
    
    if (range) {
      quill.insertText(range.index, text); // Insert text at the current selection index
      setCursorPosition(range.index + text.length); // Update cursor position
      quill.setSelection(range.index + text.length); // Move cursor to the end of the inserted text
    }
  }
  }, [cursorPosition,quillRef]);

  useEffect(() => {
    let timer;
    if (newText) {
    
        insertTextAtCursor(newText);
    // Adjust the debounce delay as needed
    }
    return () => clearTimeout(timer);
  }, [newText]);

  const handleImageClick = useCallback((event) => {
    if (event.target.tagName === "IMG") {
      const clickedImage = event.target;
      const imageSrc = clickedImage.src;

      const allImages = quillRef.current
        .getEditor()
        .root.querySelectorAll("img");
      allImages.forEach((img) => img.classList.remove(styles.selectedImg));

      clickedImage.classList.add(styles.selectedImg);

      setSelectedImage(imageSrc);
    }
  }, [setSelectedImage]);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.getEditor().getModule("toolbar").container = document.querySelector("#toolbar");
      const quill = quillRef.current.getEditor();
      const editorElement = quill.root;

      editorElement.addEventListener("click", handleImageClick);

      return () => {
        editorElement.removeEventListener("click", handleImageClick);
      };
    }
  }, [handleImageClick, quillRef]);



  // const modules = {
  //   toolbar: {
  //     container: "#toolbar",
  //   },
  // };
    const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // Allows headers 1 and 2, and normal
        [{ font: [] }],
        [{ size: [] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
        ["clean"],
      ],
    },
  };
  

  const formats = [
    "font",
    "size",
    "header",  // Add this line
    "bold",
    "italic",
    "underline",
    "color",
    "background",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  

// const modules = {
//   toolbar: {
//     container: [
//       // [{ header: "1" }, { header: "2" }, 
//       [{ font: [] }],
//       [{ size: [] }],
//       [{ color: [] }, { background: [] }],
//       [{ align: [] }],
//       // ["bold", "italic", "underline", "strike", "blockquote"],
//       ["bold", "italic", "underline", "strike", "blockquote"],
//       [
//         { list: "ordered" },
//         { list: "bullet" },
//         { indent: "-1" },
//         { indent: "+1" },
//       ],
//       ["link"], // Added video option
//       ["clean"],
//     ],
//     handlers: {
//       // image: imageHandler,
//     },
//   },
// };

// const formats = [
//   // "header",
//   "font",
//   "size",
//   "bold",
//   "italic",
//   "underline",
//   "color",
//   "background",
//   "strike",
//    "blockquote",
//   "list",
//   "bullet",
//   "indent",
//   "link",
//   "image",
//   "video",
// ];
 
return (
    <ReactQuill
      value={editorContent}
      onChange={handleEditorChange}
      modules={modules}   
       formats={formats}
      placeholder="Type your document here..."
      className={` ${styles.editor} ${isDarkMode ? styles.input_doc : styles.input_doc_light}`}
      ref={quillRef}
      onChangeSelection={handleSelectionChange}
    />
  );
});

export default QuillEditor;  

// import React, { useState, useEffect, useCallback, forwardRef } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import styles from "./blogSetting.module.css";

// const QuillEditor = forwardRef(({
//   isDarkMode,
//   onSelectionChange,
//   quillRef,
//   setSelectedImage,
//   editorContent,
//   setEditorContent,
//   newText,
//   replaceText,
//   setReplaceText,
//   setpop
// }, ref) => {
//   const [cursorPosition, setCursorPosition] = useState(null);
//   const [selectionRange, setSelectionRange] = useState(null);

//   useEffect(() => {
//     const savedContent = localStorage.getItem("editorContent");
//     if (savedContent) {
//       setEditorContent(savedContent);
//     }
//   }, [setEditorContent]);

//   useEffect(() => {
//     localStorage.setItem("editorContent", editorContent || "");
//   }, [editorContent]);

//   // useEffect(() => {
//   //   if (quillRef.current) {
//   //     const quill = quillRef.current.getEditor();
//   //     // Directly set the innerHTML of the Quill editor to the editorContent
//   //     console.log("editor contetnt", editorContent );
//   //     if (quill.root.innerHTML !== editorContent) {
//   //       quill.root.innerHTML = editorContent;
//   //     }
//   //   }
//   // }, [editorContent, quillRef]);

//   useEffect(() => {
//     if (quillRef.current) {
//       const quill = quillRef.current.getEditor();
//       // Use Quill's API to set contents
//       const delta = quill.clipboard.convert(editorContent);
//       quill.setContents(delta);
//     }
//   }, [editorContent, quillRef]);
  

//   const handleEditorChange = useCallback((content, delta, source, editor) => {
    
// console.log("content---",content);
//     setpop(true);
//     if (source === "user") {
//       setEditorContent(content);
//       setCursorPosition(editor.getSelection()?.index || null);
      
//     }
//   }, [setEditorContent]);

//   const handleSelectionChange = useCallback(() => {
//     if (quillRef.current) {
//       const quill = quillRef.current.getEditor();
//       const range = quill.getSelection();
//       if (range) {
//         const selectedText = quill.getText(range.index, range.length);
//         onSelectionChange(selectedText, range);
//         setCursorPosition(range.index);
//         setSelectionRange(range);
//       }
//     }
//   }, [onSelectionChange, quillRef]);

//   const replaceTextInSelection = useCallback(() => {
//     if (quillRef.current && replaceText && selectionRange) {
//       const quill = quillRef.current.getEditor();
//       quill.deleteText(selectionRange.index, selectionRange.length);
//       quill.insertText(selectionRange.index, replaceText);
//       setReplaceText("");
//       setSelectionRange(null);
//     }
//   }, [replaceText, setReplaceText, selectionRange, quillRef]);

//   useEffect(() => {
//     replaceTextInSelection();
//   }, [replaceText, replaceTextInSelection]);

//   const insertTextAtCursor = useCallback((text) => {
//     if (quillRef.current && cursorPosition !== null && text) {
//       const quill = quillRef.current.getEditor();
//       quill.focus();
//       const range = quill.getSelection();
//       if (range) {
//         quill.insertText(range.index, text);
//         setCursorPosition(range.index + text.length);
//         quill.setSelection(range.index + text.length);
//       }
//     }
//   }, [cursorPosition, quillRef]);

//   useEffect(() => {
//     if (newText) {
//       insertTextAtCursor(newText);
//     }
//   }, [newText, insertTextAtCursor]);

//   const handleImageClick = useCallback((event) => {
//     if (event.target.tagName === "IMG") {
//       const clickedImage = event.target;
//       const imageSrc = clickedImage.src;

//       const allImages = quillRef.current.getEditor().root.querySelectorAll("img");
//       allImages.forEach((img) => img.classList.remove(styles.selectedImg));

//       clickedImage.classList.add(styles.selectedImg);
//       setSelectedImage(imageSrc);
//     }
//   }, [setSelectedImage, quillRef]);

//   useEffect(() => {
//     if (quillRef.current) {
//       quillRef.current.getEditor().getModule("toolbar").container = document.querySelector("#toolbar");
//       const quill = quillRef.current.getEditor();
//       const editorElement = quill.root;

//       editorElement.addEventListener("click", handleImageClick);

//       return () => {
//         editorElement.removeEventListener("click", handleImageClick);
//       };
//     }
//   }, [handleImageClick, quillRef]);

//   // const modules = {
//   //   toolbar: {
//   //     container: [
//   //       [{ font: [] }],
//   //       [{ size: [] }],
//   //       [{ color: [] }, { background: [] }],
//   //       [{ align: [] }],
//   //       ["bold", "italic", "underline", "strike", "blockquote"],
//   //       [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
//   //       ["link"],
//   //       ["clean"],
//   //     ],
//   //   },
//   // };
//   const modules = {
//     toolbar: {
//       container: [
//         [{ 'header': [1, 2, false] }],  // Allows headers 1 and 2, and normal
//         [{ font: [] }],
//         [{ size: [] }],
//         [{ color: [] }, { background: [] }],
//         [{ align: [] }],
//         ["bold", "italic", "underline", "strike", "blockquote"],
//         [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
//         ["link"],
//         ["clean"],
//       ],
//     },
//   };
  

//   const formats = [
//     "font",
//     "size",
//     "header",  // Add this line
//     "bold",
//     "italic",
//     "underline",
//     "color",
//     "background",
//     "strike",
//     "blockquote",
//     "list",
//     "bullet",
//     "indent",
//     "link",
//     "image",
//     "video",
//   ];
  

//   return (
//     <ReactQuill
//       value={editorContent}
//       onChange={handleEditorChange}
//       modules={modules}
//       formats={formats}
//       placeholder="Type your document here..."
//       className={`${styles.editor} ${isDarkMode ? styles.input_doc : styles.input_doc_light}`}
//       ref={quillRef}
//       onChangeSelection={handleSelectionChange}
//     />
//   );
// });

// export default QuillEditor;



