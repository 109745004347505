import React, { useState } from "react";
import styles from "./sidebarcss.module.css";
import { NavLink } from "react-router-dom";
import smartchatactive from "../../assests/images/sidebar/smartChatActive.svg";
import smartChat from "../../assests/images/sidebar/smartChat.svg";
import dataDrive from "../../assests/images/sidebar/datadive.svg";
import dataDriveActive from "../../assests/images/sidebar/datadiveActive.svg";
import appHub from "../../assests/images/sidebar/appHub.svg";
import appHubActive from "../../assests/images/sidebar/appHubActive.svg";
import modelOpt from "../../assests/images/sidebar/modelOpt.svg";
import modelOptActive from "../../assests/images/sidebar/modelOptActive.svg";
import modelFine from "../../assests/images/sidebar/modelFine.svg";
import modelFineActive from "../../assests/images/sidebar/modelFineActive.svg";
import hubPlus from "../../assests/images/sidebar/hubplussinactive.svg";
import hubPlusActive from "../../assests/images/sidebar/hubplussactive.svg";
import appDesign from "../../assests/images/sidebar/appDesign.svg";
import appDesignActive from "../../assests/images/sidebar/appDesignActive.svg";
import texttoimage from "../../assests/images/sidebar/Texttoimage.svg";
import texttoimageActive from "../../assests/images/sidebar/TexttoimageActive.svg";
import appstoreActive from "../../assests/images/sidebar/appstoreActive.svg";
import appstoreNotactive from "../../assests/images/sidebar/appstoreNotactive.svg";
import monitoringActive from "../../assests/images/commonsidebar/monitoringactive.svg";
import monitoringNotActive from "../../assests/images/commonsidebar/monitoring.svg";
import cx from "classnames";

let navImages = [
  {
    imgNonactive: smartChat,
    imgActive: smartchatactive,
    path: "/normalchat",
    content: (
      <div className={styles.sidemid}>
        <h5>Smart Chat</h5>
      </div>
    ),
  },
  {
    imgNonactive: texttoimage,
    imgActive: texttoimageActive,
    path: "/CreateProject",
    content: (
      <div className={styles.sidemid}>
        <h5>Brew Content</h5>
        <h5>(beta)</h5>
      </div>
    ),
  },
  {
    imgNonactive: dataDrive,
    imgActive: dataDriveActive,
    path: "/database",
    content: (
      <div className={styles.sidemid}>
        <h5>Data Dive</h5>
      </div>
    ),
  },
  {
    imgNonactive: modelFine,
    imgActive: modelFineActive,
    path: "/modelfinetune",
    content: (
      <div className={styles.sidemid}>
        <h5>Model Fine Tune</h5>
      </div>
    ),
  },
  {
    imgNonactive: modelOpt,
    imgActive: modelOptActive,
    path: "/modelopt",
    content: (
      <div className={styles.sidemid}>
        <h5>Model Compress</h5>
      </div>
    ),
  },
  {
    imgNonactive: hubPlus,
    imgActive: hubPlusActive,
    path: "/hubplus",
    content: (
      <div className={styles.sidemid}>
        <h5>Hub Plus</h5>
      </div>
    ),
  },
  {
    imgNonactive: appDesign,
    imgActive: appDesignActive,
    path: "/appdesign",
    content: (
      <div className={styles.sidemid}>
        <h5>App Design</h5>
      </div>
    ),
  },
  {
    imgNonactive: appHub,
    imgActive: appHubActive,
    path: "/apphub",
    content: (
      <div className={styles.sidemid}>
        <h5>App Hub</h5>
      </div>
    ),
  },
  {
    imgNonactive: appstoreNotactive,
    imgActive: appstoreActive,
    path: "/appstore",
    content: (
      <div className={styles.sidemid}>
        <h5>App Store</h5>
      </div>
    ),
  },
  {
    imgNonactive: monitoringNotActive,
    imgActive: monitoringActive,
    path: "/monitoring",
    content: (
      <div className={styles.sidemid}>
        <h5>Monitoring</h5>
      </div>
    ),
  },
];

const Sidebar = ({ chageVar }) => {
  const [count, setCount] = useState(0);

  function reloadFun() {
    let c1 = count + 1;
    setCount(c1);
    console.log("reloadFun---", c1);
    chageVar(c1);
    // window.location.reload();
  }

  return (
    <div className={styles.full}>
      {navImages.map((ele, idx) => {
        return (
          <div className={styles.sidemid} key={idx}>
            <NavLink
              onClick={reloadFun}
              to={ele.path}
              className={cx(styles.center, "sibeIconHover")}
            >
              {/* <NavLink to={ele.path} className={cx(styles.center, "sibeIconHover")}> */}

              <img
                src={ele.imgNonactive}
                className={"nonActive"}
                alt="smartChatIcon"
              />
              <img
                src={ele.imgActive}
                className={"activeImg"}
                alt="smartChatIcon"
              />
              {/* {ele.img} */}
              {ele.content}
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default Sidebar;
