import React, { useRef, useState, useEffect } from "react";
import styles from "../../screens/ContentGeneration/blogposter.module.css";
import Blog from "../../assests/images/Blogposter/Blog.png";
import back from "../../assests/images/Blogposter/Back.png";
import close from "../../assests/images/Blogposter/close.png";
import skip from "../../assests/images/Blogposter/skip.png";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import Tittle from "./Tittle";
import Outliners from "./Outliners";
import Keypoints from "./Keypoints";
import { useParams } from "react-router-dom";
import {
  Generate_Keypoints,
  GetTittle,
  Keypoints_Finish,
  Save_Outline,
  Save_Tittle,
} from "../../commonApi";
import Charging from "../../Charging";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
const steps = ["Title", "Outliners", "Key Points"];

const ColoredConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0e8ebe",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0e8ebe",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 3,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#2B2D32",
    borderRadius: 1,
  },
}));

const Blogposter = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { status, templateId } = useParams();
  const [selectedTitle, setSelectedTitle] = useState("");
  const [outlines, setOutlines] = useState([]);
  const [instructions, setInstructions] = useState("");
  const [keywords, setKeywords] = useState("");
  const [generatedTitles, setGeneratedTitles] = useState([]);
  const toast = useRef(null);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userQuery, setUserQuery] = useState(selectedTitle || "");

  const navigate = useNavigate();

  const [keypointsData, setKeypointsData] = useState({
    blog_description: "",
    targeting_audience: "",
    role_description: "",
    words_range: "",
    call_to_actions: "",
  });

  useEffect(() => {
    const storedStage = localStorage.getItem("blogposterStage");

    // Immediately set the stage from localStorage if it exists for faster UX
    if (storedStage !== null) {
      const stage = parseInt(storedStage, 10); // Convert to number

      // Set the active step from localStorage
      switch (stage) {
        case 0:
          setActiveStep(0);
          break;
        case 1:
          setActiveStep(1);
          break;
        case 2:
          setActiveStep(2);
          break;
        case 3:
          navigate(`/CreateProject/Blogposter/poster/${templateId}`);
          break;
        default:
          setActiveStep(0);
      }
    }

    // Fetch the latest stage from the API to ensure data consistency
    const fetchData = async () => {
      setOpen(true); // Show loading indicator

      try {
        const response = await GetTittle(templateId);
        if (response.success) {
          const stage = response.data.stage;
          if (response.data.title?.Valid) {
            setUserQuery(response.data.title.String);
            setSelectedTitle(response.data.title.String);
          }
          if (response.data.outlines?.Valid) {
            const cleanedOutlines = response.data.outlines.String.replace(
              /[\[\]"]+/g,
              ""
            ) // Remove brackets [] and quotes ""
              .split(","); // Split by comma
            setOutlines(cleanedOutlines);
          }
          // Store the latest stage from API in localStorage

          // Set the active step based on the latest fetched stage
          switch (stage) {
            case 0:
              setActiveStep(0);
              break;
            case 1:
              setActiveStep(1);
              break;
            case 2:
              setActiveStep(2);
              break;
            case 3:
              // navigate(`/CreateProject/Blogposter/poster/${templateId}`);
              break;
            default:
              setActiveStep(0);
          }
        } else {
          console.error("Failed to fetch title data");
        }
      } catch (error) {
        console.error("Error fetching title data:", error);
      } finally {
        setOpen(false); // Hide loading indicator
      }
    };

    fetchData(); // Always fetch the latest data from API
  }, [templateId, navigate]);
  console.log("------------------selected1,", selectedTitle);
  console.log("------------------user2,", userQuery);
  useEffect(() => {
    // Code to trigger the dotLottie animation on component mount
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs";
    script.type = "module";
    document.body.appendChild(script);
  }, []);

  const handleNext = async () => {
    setOpen(true); // Show loading indicator
    if (!selectedTitle && !userQuery) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please generate or enter a title.",
        life: 4000, // Toast will disappear after 4 seconds
      });
      setOpen(false);
      return; // Prevent moving to the next step
    }
    try {
      if (activeStep === 0) {
        console.log(
          "------------------------------userquery,title",

          userQuery
        );
        // Save the title before moving to the next step
        const data = {
          title: selectedTitle || userQuery,
          template_unique_id: templateId,
        };
        const response = await Save_Tittle(data);
        if (response.success) {
          console.log("Title saved successfully");
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          console.error("Failed to save title:", response.message);
        }
      } else if (activeStep === 1) {
        // Collect data from Outliners component
        const data = {
          title: selectedTitle,
          instructions: instructions,
          outlines: outlines.join(","), // Convert array to comma-separated string
          keywords: keywords,
        };
        const response = await Save_Outline(data, templateId);
        if (response.success) {
          console.log("Outlines saved successfully");
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          console.error("Failed to save outlines:", response.message);
        }
      } else if (activeStep === steps.length - 1) {
        // This is the "Finish" step
        const data = {
          blog_description: keypointsData.blog_description,
          title: selectedTitle,
          outlines: outlines,
          role_description: keypointsData.role_description,
          targeting_audience: keypointsData.targeting_audience,
          words_range: keypointsData.words_range,
          keywords: keywords,
          call_to_actions: keypointsData.call_to_actions,
        };
        const response = await Generate_Keypoints(data, templateId);
        if (response.success) {
          console.log("Keypoints generated successfully:", response.data);
          setContent(response.content);

          console.log("----------------------------", content);
          // Add an additional POST request to Keypoints_Finish
          const extendedData = {
            ...data,
            content: response.content, // Assuming response.data is the content to be added
          };
          const finishResponse = await Keypoints_Finish(
            extendedData,
            templateId
          );
          if (finishResponse.success) {
            console.log("Document saved successfully:", finishResponse);
            navigate(`/CreateProject/Blogposter/poster/${templateId}`);
          } else {
            console.error("Failed to save document:", finishResponse.message);
          }
        } else {
          console.error("Failed to generate keypoints:", response.message);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      console.error("Error processing request:", error);
    } finally {
      setOpen(false); // Hide loading indicator
    }
  };

  const handleKeypointsDataChange = (newData) => {
    setKeypointsData((prevData) => ({ ...prevData, ...newData }));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Tittle
            templateId={templateId}
            onTitleSelect={setSelectedTitle}
            selectedTitle={selectedTitle}
            // setSelectedtitle={setSelectedTitle}
            generatedTitles={generatedTitles}
            setGeneratedTitles={setGeneratedTitles}
            setUserQuery={setUserQuery}
            userQuery={userQuery}
          />
        );
      case 1:
        return (
          <Outliners
            title={selectedTitle || userQuery}
            onInstructionsChange={setInstructions}
            onKeywordsChange={setKeywords}
            onOutlinesChange={setOutlines}
            instructions={instructions}
            keywords={keywords}
            outlines={outlines}
          />
        );
      case 2:
        return (
          <Keypoints
            title={selectedTitle || userQuery}
            outlines={outlines}
            onDataChange={handleKeypointsDataChange}
            keypointsData={keypointsData}
            templateId={templateId}
          />
        );
      default:
        return <Tittle templateId={templateId} />;
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <Charging open={open} />
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.blog}>
            <img
              className={styles.back}
              src={back}
              alt="Back"
              onClick={handleBack}
            />
            <img src={Blog} alt="Blog" />
            <h5>Blog Poster Builder</h5>
            <div className={styles.buidler}>
              <img src={close} alt="Close" />
            </div>
          </div>
          <div className={styles.tittle}>
            <Box sx={{ width: "100%" }}>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<ColoredConnector />}
              >
                {steps?.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      <h5 className={styles.white}>{label}</h5>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>
          <div className={styles.skip}>
            {activeStep > 0 && activeStep < steps.length - 1 && (
              <button className={styles.skipbtn} onClick={handleNext}>
                <img src={skip} alt="Skip" /> Skip
              </button>
            )}
            <button className={styles.commonbtn} onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </button>
          </div>

          {renderStepContent(activeStep)}
        </div>
        <div className={styles.right}>
          <script
            src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs"
            type="module"
          ></script>

          <dotlottie-player
            src="https://lottie.host/0dba2f31-d285-4877-ba67-83236e0a6abb/KHbEdt4VV1.json"
            background="transparent"
            speed="1"
            className={styles.dottie}
            loop
            autoplay
          ></dotlottie-player>
        </div>
      </div>
    </div>
  );
};

export default Blogposter;
